import React from 'react'
import { Link } from 'gatsby'

const FooterEn = () => (
    <div>
        <div className="columns footer-content">
          <div className="column">
            <div className="footer-title">Chemical treatments</div>
            <Link className='footer-text link-primary' to='/water-treatment'>Water treatment</Link>
            <Link className='footer-text link-primary' to='/oil-field-chemicals'>Oil field chemicals</Link>
            <Link className='footer-text link-primary' to='/sugar-processing-chemicals'>Sugar industry</Link>
            <Link className='footer-text link-primary' to='/paper-processing-chemicals'>Paper processing</Link>
          </div>
          <div className="column">
            <Link className='footer-title' to='/contacts'>Contact Us</Link>
            <div className="footer-text">UAB „Inžinerinė ekologija”</div>
            <div className="footer-text">LT-49142, Kaunas, Lithuania</div>
            <div className="footer-text">Ašigalio g. 6 II korpusas</div>
          </div>
          <div className="column">
            <div className="footer-text">E-mail: info@in-eco.biz</div>
            <div className="footer-text">Tel.: +370 37 21 42 25</div>
            <div className="footer-text">Fax: +370 37 21 42 26</div>
          </div>
        </div>
        <div className="columns">
          <div className="column">
            <Link className='footer-title link-primary' to='/purolite'>Purolite resins</Link>
            <br/>
            <Link className='footer-title link-primary' to='/about-us'>About Us</Link>
          </div>
        </div>
    </div>
)

export default FooterEn