import React, { Component } from 'react'
import { Link } from 'gatsby'
import NavBarEn from './NavBarEn'
import NavBarLt from './NavBarLt'
import NavBarRu from './NavBarRu'
import mainLogo from './logo.png'

class NavBar extends Component {

  state = {
    isActive: false,
    activeLanguageDropdown: false,
    navbar: null
  }

  onClickBurgerHandler = () => {
    if (this.state.isActive) {
      this.setState({isActive : false})
    } else{
      this.setState({isActive : true})
    } 
  }

  clickLanguageDropdown = () => {
    this.setState({
      activeLanguageDropdown:!this.state.activeLanguageDropdown
    })
  }

  render () {
    let isActive = this.state.isActive;
    return (
      <div className='navbar' id='navbar'>
        <div className="navbar-brand">
          <Link className='navbar-item navbar-item-logo' to={(typeof window !== `undefined`) ? ( (this.state.navbar === 'L') ? '/lt/' : 
          (this.state.navbar === 'R') ? '/ru/' :
          '/') : '/'}>
            <img className='navbar-logo-image' src={mainLogo} alt='IN-ECO Logo'/>
          </Link>
          <button
            className={`navbar-burger burger ${isActive && 'is-active'}`}
            onClick={this.onClickBurgerHandler}
            aria-label="hidden"
            aria-expanded="false"
            data-target="navbarBasicExample">
            <span aria-hidden="true"></span>
            <span aria-hidden="true"></span>
            <span aria-hidden="true"></span>
          </button>
        </div>
        <div className={`navbar-menu ${isActive && 'is-active'}`}>
          {(typeof window !== `undefined`) ? ( (this.state.navbar === 'L') ? <NavBarLt langDropDown={this.state.activeLanguageDropdown} langClick={this.clickLanguageDropdown}/> : 
          (this.state.navbar === 'R') ? <NavBarRu langDropDown={this.state.activeLanguageDropdown} langClick={this.clickLanguageDropdown}/> :
          <NavBarEn langDropDown={this.state.activeLanguageDropdown} langClick={this.clickLanguageDropdown}/>) : null}
        </div>
      </div>
    )
  }

  navbarLanguageSelectorHandler = () => {
    if (window.location.pathname.split('/')[1] === 'lt') {
      return 'L'
    }
    else if (window.location.pathname.split('/')[1] === 'ru') {
      return 'R'
    }
    else {
      return 'E'
    }
  }
  
  componentDidMount () {
    this.setState({navbar: this.navbarLanguageSelectorHandler()})
  }
}

export default NavBar