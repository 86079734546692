import React, { Component } from 'react'
import { Events, animateScroll as scroll, scrollSpy } from 'react-scroll'
import Arrow from '../../images/arrow.png';
import FooterEn from './FooterEn';
import FooterLt from './FooterLt';
import FooterRu from './FooterRu';

class Footer extends Component {
  state = {
    footerLanguage: null
  }

  footerLanguageSelectorHandler = () => {
    if (window.location.pathname.split('/')[1] === 'lt') {
      return <FooterLt/>
    }
    else if (window.location.pathname.split('/')[1] === 'ru') {
      return <FooterRu/>
    }
    else {
      return <FooterEn langDropDown={this.state.activeLanguageDropdown} langClick={this.clickLanguageDropdown}/>
    }
  }

  componentDidMount() {
    scrollSpy.update();
    this.setState({footerLanguage: this.footerLanguageSelectorHandler()})

  };
  componentWillUnmount(){
    Events.scrollEvent.remove('begin');
    Events.scrollEvent.remove('end');
  };

  scrollToTop() {
    scroll.scrollToTop();
  };

  render () {
    return (
      <div className='footer'>
        {this.state.footerLanguage}
        <div className="columns">
          <div className="column copy-container">
            <div className="footer-text copy">© IN-ECO 2019</div>
          </div>
          <div className="column">
            <button aria-label="Scroll to top" onClick={this.scrollToTop} className="button scroll-to-up">
              <img src={Arrow} alt="" className="scroll-to-up-img"/>
            </button>
          </div>
        </div>
      </div>
    )
  }
}

export default Footer