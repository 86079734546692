import React from 'react'
import { Link } from 'gatsby'

const FooterRu = () => (
    <div>
        <div className="columns footer-content">
          <div className="column">
            <div className="footer-title">Химическая обработка</div>
            <Link className='footer-text link-primary' to='/ru/водоподготовка'>Водоподготовка</Link>
            <Link className='footer-text link-primary' to='/ru/реагенты-газо-и-нефтепромысла'>Газо и нефтепромысел</Link>
            <Link className='footer-text link-primary' to='/ru/реагенты-сахарного-производства'>Сахарная индустрия</Link>
            <Link className='footer-text link-primary' to='/ru/реагенты-бумажной-промышленности'>Бумажная промышленность</Link>
            
          </div>
          <div className="column">
            <Link className='footer-title' to='/ru/контакты'>Контакты</Link>
            <div className="footer-text">UAB „Inžinerinė ekologija”</div>
            <div className="footer-text">LT-49142, Kaunas, Lithuania</div>
            <div className="footer-text">Ašigalio g. 6 II korpusas</div>
          </div>
          <div className="column">
            <div className="footer-text">E-mail: info@in-eco.biz</div>
            <div className="footer-text">Tel.: +370 37 21 42 25</div>
            <div className="footer-text">Fax: +370 37 21 42 26</div>
          </div>
        </div>
        <div className="columns">
          <div className="column">
            <Link className='footer-title link-primary' to='/ru/purolite'>Смолы Purolite</Link>
            <br/>
            <Link className='footer-title link-primary' to='/ru/о-нас'>О-нас</Link>
          </div>
        </div>
    </div>
)

export default FooterRu