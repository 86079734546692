import React from 'react'
import { enPath, ruPathFromLt} from './pathSwitches/pathFunc'
import { Link } from 'gatsby'

const navBarLt = (props) => {
    let activeLanguageDropdown = props.langDropDown;
    return(
      <div className='navbar-end'>
        <div className='navbar-item has-dropdown is-hoverable is-arrowless'>
          <div className='navbar-link'>Cheminiai reagentai</div>
          <div className="navbar-dropdown">
            <Link className='navbar-item' to='/lt/vandens-apdirbimas' >Vandens apdirbimas</Link>
            <Link className='navbar-item' to='/lt/naftos-ir-dujų-gavyba' >Naftos ir dujų gavyba</Link>
            <Link className='navbar-item' to='/lt/reagentai-cukraus-pramonei' >Cukraus pramonė</Link>
            <Link className='navbar-item' to='/lt/reagentai-popieriaus-pramonei'>Popieriaus pramonė</Link>
          </div>
        </div>
        <Link className='navbar-item' to='/lt/purolite'>Purolite dervos</Link>
        <Link className='navbar-item' to='/lt/apie-mus'>Apie mus</Link>
        <Link className='navbar-item navbar-item-selected' to='/lt/kontaktai'>Kontaktai</Link>
        <div
          className={`navbar-item has-dropdown is-hoverable language-switcher ${activeLanguageDropdown ? 'is-active' : ''}`}
          onClick={props.langClick}>
          <div className='navbar-link'>LT</div>
          <div className="navbar-dropdown navbar-dropdown-language">
            <Link className='navbar-item' to={enPath()}>EN</Link>
            <Link className='navbar-item' to={ruPathFromLt()}>RU</Link>
          </div>
        </div>
      </div>
    )
}

export default navBarLt