import React from 'react'
import { ltPathFromEn, ruPathFromEn } from './pathSwitches/pathFunc'
import { Link } from 'gatsby'

const navBarEn = (props) => {
    let activeLanguageDropdown = props.langDropDown;
    return(
      <div className='navbar-end'>
        <div className='navbar-item has-dropdown is-hoverable is-arrowless'>
          <div className='navbar-link'>Chemical treatments</div>
          <div className="navbar-dropdown">
            <Link className='navbar-item' to='/water-treatment' >Water treatment</Link>
            <Link className='navbar-item' to='/oil-field-chemicals' >Oil field chemicals</Link>
            <Link className='navbar-item' to='/sugar-processing-chemicals' >Sugar industry</Link>
            <Link className='navbar-item' to='/paper-processing-chemicals'>Paper processing</Link>
          </div>
        </div>
        <Link className='navbar-item' to='/purolite'>Purolite resins</Link>
        <Link className='navbar-item' to='/about-us'>About us</Link>
        <Link className='navbar-item navbar-item-selected' to='/contacts'>Contacts</Link>
        <div
          className={`navbar-item has-dropdown is-hoverable language-switcher ${ activeLanguageDropdown ? 'is-active' : ''}`}
          onClick={props.langClick}>
          <div className='navbar-link'>EN</div>
          <div className="navbar-dropdown navbar-dropdown-language">
            <Link className='navbar-item' to={ltPathFromEn()}>LT</Link>
            <Link className='navbar-item' to={ruPathFromEn()}>RU</Link>
          </div>
        </div>
      </div>
    )
}

export default navBarEn