export const enPath = () => {
    switch (window.location.pathname.split("/")[2]) {
        case '%D0%BE-%D0%BD%D0%B0%D1%81':
            return '/about-us';
        case 'apie-mus':
            return '/about-us';
        case 'purolite':
            return '/purolite'
        case 'kontaktai':
            return '/contacts';
        case '%D0%BA%D0%BE%D0%BD%D1%82%D0%B0%D0%BA%D1%82%D1%8B':
            return '/contacts';
        case 'vandens-apdirbimas':
            return '/water-treatment';
        case '%D0%B2%D0%BE%D0%B4%D0%BE%D0%BF%D0%BE%D0%B4%D0%B3%D0%BE%D1%82%D0%BE%D0%B2%D0%BA%D0%B0':
            return '/water-treatment'; 
        case 'naftos-ir-duj%C5%B3-gavyba':
            return '/oil-field-chemicals'; 
        case '%D1%80%D0%B5%D0%B0%D0%B3%D0%B5%D0%BD%D1%82%D1%8B-%D0%B3%D0%B0%D0%B7%D0%BE-%D0%B8-%D0%BD%D0%B5%D1%84%D1%82%D0%B5%D0%BF%D1%80%D0%BE%D0%BC%D1%8B%D1%81%D0%BB%D0%B0':
            return '/oil-field-chemicals'; 
        case 'reagentai-cukraus-pramonei':
            return '/sugar-processing-chemicals'; 
        case '%D1%80%D0%B5%D0%B0%D0%B3%D0%B5%D0%BD%D1%82%D1%8B-%D1%81%D0%B0%D1%85%D0%B0%D1%80%D0%BD%D0%BE%D0%B3%D0%BE-%D0%BF%D1%80%D0%BE%D0%B8%D0%B7%D0%B2%D0%BE%D0%B4%D1%81%D1%82%D0%B2%D0%B0':
            return '/sugar-processing-chemicals'; 
        case 'reagentai-popieriaus-pramonei':
            return '/paper-processing-chemicals'; 
        case '%D1%80%D0%B5%D0%B0%D0%B3%D0%B5%D0%BD%D1%82%D1%8B-%D0%B1%D1%83%D0%BC%D0%B0%D0%B6%D0%BD%D0%BE%D0%B9-%D0%BF%D1%80%D0%BE%D0%BC%D1%8B%D1%88%D0%BB%D0%B5%D0%BD%D0%BD%D0%BE%D1%81%D1%82%D0%B8':
            return '/paper-processing-chemicals'; 
        default:
            return '/'
    }
}

export const ltPathFromEn = () => {
    switch (window.location.pathname.split("/")[1]) {
        case 'about-us':
            return '/lt/apie-mus';
        case 'purolite':
            return '/lt/purolite';
        case 'contacts':
            return '/lt/kontaktai';
        case 'water-treatment':
            return '/lt/vandens-apdirbimas';
        case 'oil-field-chemicals':
            return '/lt/naftos-ir-dujų-gavyba';
        case 'sugar-processing-chemicals':
            return '/lt/reagentai-cukraus-pramonei';
        case 'paper-processing-chemicals':
            return '/lt/reagentai-popieriaus-pramonei';
        default:
            return '/lt'
    }
}

export const ltPathFromRu = () => {
    switch (window.location.pathname.split("/")[2]) {
        case '%D0%BE-%D0%BD%D0%B0%D1%81':
            return '/lt/apie-mus';
        case 'purolite':
            return '/lt/purolite';
        case '%D0%BA%D0%BE%D0%BD%D1%82%D0%B0%D0%BA%D1%82%D1%8B':
            return '/lt/kontaktai';
        case '%D0%B2%D0%BE%D0%B4%D0%BE%D0%BF%D0%BE%D0%B4%D0%B3%D0%BE%D1%82%D0%BE%D0%B2%D0%BA%D0%B0':
            return '/lt/vandens-apdirbimas';
        case '%D1%80%D0%B5%D0%B0%D0%B3%D0%B5%D0%BD%D1%82%D1%8B-%D0%B3%D0%B0%D0%B7%D0%BE-%D0%B8-%D0%BD%D0%B5%D1%84%D1%82%D0%B5%D0%BF%D1%80%D0%BE%D0%BC%D1%8B%D1%81%D0%BB%D0%B0':
            return '/lt/naftos-ir-dujų-gavyba';
        case '%D1%80%D0%B5%D0%B0%D0%B3%D0%B5%D0%BD%D1%82%D1%8B-%D1%81%D0%B0%D1%85%D0%B0%D1%80%D0%BD%D0%BE%D0%B3%D0%BE-%D0%BF%D1%80%D0%BE%D0%B8%D0%B7%D0%B2%D0%BE%D0%B4%D1%81%D1%82%D0%B2%D0%B0':
            return '/lt/reagentai-cukraus-pramonei';
        case '%D1%80%D0%B5%D0%B0%D0%B3%D0%B5%D0%BD%D1%82%D1%8B-%D0%B1%D1%83%D0%BC%D0%B0%D0%B6%D0%BD%D0%BE%D0%B9-%D0%BF%D1%80%D0%BE%D0%BC%D1%8B%D1%88%D0%BB%D0%B5%D0%BD%D0%BD%D0%BE%D1%81%D1%82%D0%B8':
            return '/lt/reagentai-popieriaus-pramonei';
        default:
            return '/lt'
    }
}

export const ruPathFromEn = () => {
    switch (window.location.pathname.split("/")[1]) {
        case 'about-us':
            return '/ru/о-нас';
        case 'purolite':
            return '/ru/purolite';
        case 'contacts':
            return '/ru/контакты';
        case 'water-treatment':
            return '/ru/водоподготовка';
        case 'oil-field-chemicals':
            return '/ru/реагенты-газо-и-нефтепромысла';
        case 'sugar-processing-chemicals':
            return '/ru/реагенты-сахарного-производства';
        case 'paper-processing-chemicals':
            return '/ru/реагенты-бумажной-промышленности';
        default:
            return '/ru'
    }
}

export const ruPathFromLt = () => {
    switch (window.location.pathname.split("/")[2]) {
        case 'apie-mus':
            return '/ru/о-нас';
        case 'purolite':
            return '/ru/purolite';
        case 'kontaktai':
            return '/ru/контакты';
        case 'vandens-apdirbimas':
            return '/ru/водоподготовка';
        case 'naftos-ir-duj%C5%B3-gavyba':
            return '/ru/реагенты-газо-и-нефтепромысла';
        case 'reagentai-cukraus-pramonei':
            return '/ru/реагенты-сахарного-производства';
        case 'reagentai-popieriaus-pramonei':
            return '/ru/реагенты-бумажной-промышленности';
        default:
            return '/ru'
    }
}

