import React from 'react'
import PropTypes from 'prop-types'
import { Helmet } from 'react-helmet'
import { StaticQuery, graphql } from 'gatsby'

import NavBar from './Navbar'
import Footer from './Footer/index'
import '../styles/index.scss'

const Layout = ({ children }) => (
  <StaticQuery
    query={graphql`
      query SiteTitleQuery {
        site {
          siteMetadata {
            title
          }
        }
      }
    `}
    render={data => (
      <>
        <Helmet
          title={data.site.siteMetadata.title}
          meta={[
            {
              name: 'description',
              content:
                'IN-ECO - European manufacturer of chemicals. Our products are internationally acclaimed in water treatment, sugar processing, paper processing and oil and gas processing industries.',
            },
          ]}
        >
          <html lang="en" />
        </Helmet>
        <div className="container main-container">
          <NavBar />
          <div className="content-container">{children}</div>
          <Footer />
        </div>
      </>
    )}
  />
)

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
