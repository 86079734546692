import React from 'react'
import { enPath, ltPathFromRu } from './pathSwitches/pathFunc'
import { Link } from 'gatsby'

const navBarRu = (props) => { 
    let activeLanguageDropdown =  props.langDropDown;
    return(
      <div className='navbar-end'>
        <div className='navbar-item has-dropdown is-hoverable is-arrowless'>
          <div className='navbar-link'>Химическая обработка</div>
          <div className="navbar-dropdown">
            <Link className='navbar-item' to='/ru/водоподготовка' ><div className='' >Водоподготовка</div></Link>
            <Link className='navbar-item' to='/ru/реагенты-газо-и-нефтепромысла' ><div className='' >Газо и нефтепромысел</div> </Link>
            <Link className='navbar-item' to='/ru/реагенты-сахарного-производства' ><div className='' >Сахарная индустрия</div> </Link>
            <Link className='navbar-item' to='/ru/реагенты-бумажной-промышленности'><div className='' >Бумажная промышленность</div> </Link>
          </div>
        </div>
        <Link className='navbar-item' to='/ru/purolite'><div className='' >Смолы Purolite</div> </Link>
        <Link className='navbar-item' to='/ru/о-нас'><div className='' >О нас</div></Link>
        <Link className='navbar-item navbar-item-selected' to='/ru/контакты'><div className='' >Контакты</div> </Link>
        <div
          className={`navbar-item has-dropdown is-hoverable language-switcher ${activeLanguageDropdown ? 'is-active' : ''}`}
          onClick={props.langClick}>
          <div className='navbar-link'>RU</div>
          <div className="navbar-dropdown navbar-dropdown-language">
            <Link className='navbar-item' to={enPath()}>EN</Link>
            <Link className='navbar-item' to={ltPathFromRu()}>LT</Link>
          </div>
        </div>
      </div>
    )
}

export default navBarRu