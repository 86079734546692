import React from 'react'
import { Link } from 'gatsby'

const FooterLt = () => (
    <div>
        <div className="columns footer-content">
          <div className="column">
            <div className="footer-title">Cheminiai reagentai</div>
            <Link className='footer-text link-primary' to='/lt/vandens-apdirbimas'>Vandens apdirbimas</Link>
            <Link className='footer-text link-primary' to='/lt/naftos-ir-dujų-gavyba'>Naftos ir dujų gavyba</Link>
            <Link className='footer-text link-primary' to='/lt/reagentai-cukraus-pramonei'>Cukraus pramonė</Link>
            <Link className='footer-text link-primary' to='/lt/reagentai-popieriaus-pramonei'>Popieriaus pramonė</Link>
            
          </div>
          <div className="column">
            <Link className='footer-title' to='/lt/kontaktai'>Kontaktai</Link>
            <div className="footer-text">UAB „Inžinerinė ekologija”</div>
            <div className="footer-text">LT-49142, Kaunas, Lithuania</div>
            <div className="footer-text">Ašigalio g. 6 II korpusas</div>
          </div>
          <div className="column">
            <div className="footer-text">E-mail: info@in-eco.biz</div>
            <div className="footer-text">Tel.: +370 37 21 42 25</div>
            <div className="footer-text">Fax: +370 37 21 42 26</div>
          </div>
        </div>
        <div className="columns">
          <div className="column">
            <Link className='footer-title link-primary' to='/lt/purolite'>Purolite dervos</Link>
            <br/>
            <Link className='footer-title link-primary' to='/lt/apie-mus'>Apie mus</Link>
          </div>
        </div>
    </div>
)

export default FooterLt